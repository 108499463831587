<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="localeDrawer = !localeDrawer"></v-app-bar-nav-icon>

      <v-text-field
        v-model="search"
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        clearable
      ></v-text-field>


      <v-spacer></v-spacer>

      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list subheader dense>
          <v-list-item>
            <v-checkbox
              v-model="dense"
              label="Dense"
              dense
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="mobileOptimization"
              label="Mobile optimization"
              dense
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="keyFilterable"
              label="Search keys"
              dense
            ></v-checkbox>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click="createDialog = true;">
            <v-list-item-title>Create new</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click="downloadJsonFiles">
            <v-list-item-title>Download</v-list-item-title>
          </v-list-item>
          
        </v-list>
      </v-menu>

      <v-app-bar-nav-icon @click.stop="editDrawer = !editDrawer">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="localeDrawer" app clipped bottom>
      <v-list nav dense>
        <v-subheader>Locales</v-subheader>
        <v-list-item-group
          v-model="selectedLocaleIndices"
          color="primary"
          multiple
        >
          <template v-for="(locale) in locales">
            <v-list-item :key="`locale-${locale}`">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ locale }} ({{ initialNumItemsPerLocale[locale] }})</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>

          </template>
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :item-class="getRowClass"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :search="search"
          :items-per-page="-1"
          :height="tableHeight"
          :dense="dense"
          :mobile-breakpoint="mobileOptimization ? 600 : 0"
          item-key="key"
          hide-default-footer
          fixed-header
          class="flex-grow-1"
          @click:row="onRowClick"
        >
          <!-- <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
            {{ item[header.value] }}
          </template> -->
        </v-data-table>
      </v-layout>

      <MessageSnackBar ref="messageSnackBar"></MessageSnackBar>
    </v-main>

    <v-navigation-drawer v-model="editDrawer" width="360" mobile-breakpoint="960" app right bottom>
      <div class="pa-4">
        <v-card class="pa-4">
          <v-text-field
            v-model="editingItem.key"
            label="key"
            @focus="$event.target.select()"></v-text-field>
          <div
            v-for="locale in selectedLocales"
            :key="`edit-field-${locale}`"
            class="d-flex"
          >
            <v-textarea
              v-model="editingItem[locale]"
              :label="locale"
              class="flex-grow"
              rows="1"
              auto-grow
              @focus="$event.target.select()"
              @change="onValueEdit(locale)"
            >
            </v-textarea>
            <v-icon @click="addTranslateText(editingItem[locale], false)">
              mdi-translate
            </v-icon>
            <v-icon @click="addTranslateText(editingItem[locale], true)">
              mdi-translate-variant
            </v-icon>
          </div>
        </v-card>
      </div>

      <div class="pa-4">
        <v-card
          v-for="(item, i) in [translateItem, reverseTranslateItem]"
          :key="`translate-${i}`"
          class="pa-2 mb-2"
        >
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-select v-model="item.fromLocale" :items="selectedLocales" dense hide-details solo></v-select>
            </v-col>
            <v-col class="d-flex" cols="6">
              <v-select v-model="item.toLocale" :items="selectedLocales" dense hide-details solo></v-select>
            </v-col>
          </v-row>
          <v-textarea class="my-2" type="text" v-model="item.text" hide-details solo auto-grow></v-textarea>
          <div class="d-flex">
            <v-btn class="flex-grow-1 mr-2" color="primary" :loading="loading" :disabled="loading" @click="translate(item)">
              <v-icon dark>
                mdi-translate
              </v-icon>
            </v-btn>
            <v-btn class="primary mr-2" @click="copyToClipboard(item.text)">
              <v-icon dark>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <v-btn color="error" @click="item.text = '';">
              <v-icon dark>
                mdi-backspace
              </v-icon>
            </v-btn>
          </div>
          <v-textarea class="mt-2" type="text" v-model="item.translatedText" hide-details solo auto-grow></v-textarea>
          <div class="d-flex mt-2">
            <v-btn class="primary flex-grow-1" @click="copyToClipboard(item.translatedText)">
              <v-icon dark>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <v-btn v-if="item === translateItem" class="flex-grow-1 ml-2" @click="updateReverseTranslateText">
              <v-icon dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-dialog v-if="loginRequired" v-model="loginRequired" persistent max-width="600px">
      <Login
        class="pa-4"
        @logged="loginRequired = false; initialize();"
      />
    </v-dialog>
    
    <v-dialog v-if="createDialog" v-model="createDialog" persistent max-width="600px">
      <Create
        class="pa-4"
        @cancel="createDialog = false;"
        @created="createDialog = false; refreshTableData(true);"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import Create from '@/components/Create.vue';
import Login from '@/components/Login.vue';
import MessageSnackBar from '@/components/MessageSnackBar.vue';

export default {
  name: 'App',

  components: {
    Create,
    Login,
    MessageSnackBar,
  },

  data: () => ({
    loginRequired: false,

    loading: false,

    localeDrawer: true,
    locales: [],
    initialNumItemsPerLocale: {},
    selectedLocaleIndices: [],
    selectedLocales: [],
    fetchedLocalesMap: {},

    itemsMap: {},

    tableHeight: 100,
    headers: [],
    items: [],
    sortBy: 'key',
    sortDesc: false,
    search: '',
    mobileOptimization: false,
    dense: true,
    keyFilterable: false,

    createDialog: false,

    editDrawer: false,
    editingItem: {},

    translateItem: {
      fromLocale: 'en',
      toLocale: 'en',
      text: '',
      translatedText: '',
    },
    reverseTranslateItem: {
      fromLocale: 'en',
      toLocale: 'en',
      text: '',
      translatedText: '',
    },
    translateLocaleConfigured: false,
  }),

  created() {
    this.$root.setLoginRequired = this.setLoginRequired;
    this.initialize();
  },

  mounted() {
    this.$root.messageSnackBar = this.$refs.messageSnackBar;
  },

  watch: {
    selectedLocaleIndices: {
      handler() {
        this.refreshTableData();
      },
      deep: true,
    },
    keyFilterable: {
      handler() {
        if (this.headers.length === 0) return;
        this.headers[0].filterable = this.keyFilterable;
        this.headers = [...this.headers];
      },
    },
  },

  methods: {
    async initialize() {
      await this.getLocales();
    },

    getRowClass(item) {
      if (item.index === this.editingItem.index) return 'light-blue lighten-5';
      return '';
    },

    onResize() {
      this.tableHeight = window.innerHeight - 64;
    },

    onRowClick(item) {
      this.editDrawer = true;
      this.editingItem = {...item};
      if (!this.translateLocaleConfigured) {
        this.translateItem.toLocale = this.selectedLocales[this.selectedLocales.length-1];
        this.reverseTranslateItem.fromLocale = this.selectedLocales[this.selectedLocales.length-1];
        this.translateLocaleConfigured = true;
      }
    },

    async onValueEdit(locale) {
      if (this.loading) return;
      this.loading = true;

      const key = this.editingItem.key;
      const newValue = this.editingItem[locale];

      let res = await this.$apiRequest({
        method: 'post',
        url: '/update',
        data: {
          locale: locale,
          key: key,
          value: newValue,
        },
      });
      this.loading = false;
      if (!res || !res.data) return;

      this.itemsMap[key][locale] = newValue;
      this.items[this.editingItem.index][locale] = newValue;
    },

    setLoginRequired() {
      this.loginRequired = true;
    },

    async getLocales() {
      if (this.loading) return;
      this.loading = true;

      let res = await this.$apiRequest({
        method: 'get',
        url: '/locales',
      });
      this.loading = false;
      if (!res || !res.data) return;

      let locales = [];
      let initialNumItemsPerLocale = {};
      for (let locale of res.data.locales) {
        locales.push(locale.name);
        initialNumItemsPerLocale[locale.name] = locale.count;
      }

      this.locales = locales;
      this.initialNumItemsPerLocale = initialNumItemsPerLocale;
    },

    async refreshTableData(force) {
      if (force === undefined) force = false;

      if (this.loading) return;
      this.loading = true;
      
      let newHeaders = [];
      newHeaders.push({
        text: 'key',
        value: 'key',
        class: 'key-cell',
        cellClass: 'key-cell',
        filterable: this.keyFilterable,
      });

      let newSelectedLocales = [];
      for (let i of this.selectedLocaleIndices) {
        let locale = this.locales[i];
        newSelectedLocales.push(locale);
        newHeaders.push({ text: locale, value: locale });
      }
      this.selectedLocales = newSelectedLocales;
      this.headers = newHeaders;

      if (force) this.itemsMap = {};

      for (let locale of this.selectedLocales) {
        if (force || this.fetchedLocalesMap[locale] === undefined) {
          let res = await this.$apiRequest({
            method: 'get',
            url: '/all',
            data: {
              locale: locale,
            },
          });
          if (res && res.data && res.data.entries) {
            for (let entry of res.data.entries) {
              if (this.itemsMap[entry.key] === undefined) {
                this.itemsMap[entry.key] = {};
              }
              this.itemsMap[entry.key][locale] = entry.value;
            }
            this.fetchedLocalesMap[locale] = true;
          }
        }
      }

      let newItems = [];
      let keys = Object.keys(this.itemsMap);
      for (let i in keys) {
        const key = keys[i];
        let item = { ...this.itemsMap[key], index: i, originalKey: key, key: key };
        newItems.push(item);
      }
      this.items = newItems;

      this.editingItem = {};
      this.loading = false;
    },

    addTranslateText(text, lower) {
      let finalText = text;
      if (lower) {
        let textComponents = text.split(' ');
        for (let i in textComponents) {
          if (i != 0 && textComponents[i] !== 'Capriccio') {
            textComponents[i] = textComponents[i].toLowerCase();
          }
        }
        finalText = textComponents.join(' ');
      }
      if (this.translateItem.text === '') this.translateItem.text = finalText;
      else this.translateItem.text += `\n${finalText}`;
      this.translate(this.translateItem);
    },
    updateReverseTranslateText() {
      this.reverseTranslateItem.text = `${this.translateItem.translatedText}`;
      this.translate(this.reverseTranslateItem);
    },
    async translate(item) {
      let res = await this.$apiRequest({
        method: 'get',
        url: '/tr',
        data: {
          fromLocale: item.fromLocale,
          toLocale: item.toLocale,
          text: item.text,
        },
      });
      this.loading = false;
      if (!res || !res.data) return;

      item.translatedText = res.data.translated;
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
    },

    downloadJsonFiles() {
      location.href = this.$apiFullUrl('/download_json');
    },
  },
};
</script>

<style>
html {
  overflow-y: auto;
}

.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 80% !important;
}

.key-cell {
  min-width: 80px;
  word-break: break-all;
}

</style>