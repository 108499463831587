<template>
  <v-card>
    <v-form ref="form" :disabled="processing" @submit.prevent="processForm" lazy-validation>
      <v-text-field type="text" v-model="key" label="Key" required autofocus clearable></v-text-field>
      <v-text-field type="text" v-model="locale" label="Locale" required clearable></v-text-field>
      <v-text-field type="text" v-model="value" label="Value" required clearable></v-text-field>

      <div class="mt-4 d-flex justify-end">
        <v-btn class="ml-2" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn class="ml-2" type="submit" color="primary" @click="processForm">Create</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'Create',

  components: {
  },

  data: () => ({
    processing: false,

    key: '',
    locale: '',
    value: '',
  }),

  created() {
  },

  methods: {
    async processForm() {
      if (this.processing) return;

      let isValid = this.$refs.form.validate();
      if (!isValid) return;

      this.processing = true;

      let res = await this.$apiRequest({
        method: 'post',
        url: '/update',
        data: {
          locale: this.locale,
          key: this.key,
          value: this.value,
        },
      });
      this.processing = false;
      if (!res || !res.data) return;

      this.$emit('created');
    },
  }
  
};
</script>

<style scoped>
.item-detail-table th {
  width: 0.1%;
  white-space: nowrap;
}
</style>