<template>
  <v-snackbar :color="color" v-model="showSnackbar" top right>
    <div class="d-flex flex-row align-start">
      <v-icon left>{{ icon }}</v-icon>
      <span class="flex-grow-1" style="padding-top: 2px;">{{ message }}</span>
    </div>
    <template v-slot:action="{ attrs }">
      <div>
        <v-btn icon v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'MessageSnackBar',

  data: () => ({
    showSnackbar: false,
    color: 'brown',
    message: '',
    icon: 'mdi-check-circle',
  }),

  methods: {
    showError(message) {
      this.message = message;
      this.color = 'brown';
      this.icon = 'mdi-alert';
      this.showSnackbar = true;
    },
    showSuccess(message) {
      this.message = message;
      this.color = 'success';
      this.icon = 'mdi-check-circle';
      this.showSnackbar = true;
    }
  }
}
</script>
