<template>
  <v-card>
    <v-form ref="form" :disabled="processing" @submit.prevent="processForm" lazy-validation>
      <v-text-field type="password" v-model="password" label="Password" required autofocus></v-text-field>

      <div class="mt-4 d-flex justify-end">
        <v-btn type="submit" color="primary" @click.prevent="processForm">Login</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'Login',

  components: {
  },

  data: () => ({
    processing: false,
    password: '',
  }),

  created() {
  },

  methods: {
    async processForm() {
      console.log('processForm');
      if (this.processing) return;

      let isValid = this.$refs.form.validate();
      if (!isValid) return;

      this.processing = true;

      let res = await this.$apiRequest({
        method: 'post',
        url: '/login',
        data: {
          password: this.password,
        },
      });
      this.processing = false;

      if (!res || !res.data) return;
      if (res.data.result === 'ok') {
        this.$emit('logged');
      }
    },
  }
  
};
</script>
