import axios from 'axios';
import Vue from 'vue';

import App from './App.vue';
import vuetify from './plugins/vuetify';

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;


// API mixins
Vue.mixin({
  methods: {
    async $apiRequest({config={}, method, url, data, handleError=true} = {}) {
      let axiosConfig = config;
      if (method) axiosConfig.method = method;
      if (url) {
        axiosConfig.url = this.$apiFullUrl(url);
      }
      if (data) {
        if (axiosConfig.method === 'get') {
          axiosConfig.params = data;
        }
        else {
          axiosConfig.data = data;
        }
      }
    
      return axios(axiosConfig).catch((error) => {
        if (handleError) {
          if (this.$apiHandleError(error)) return;
        }
        throw error;
      });
    },

    $apiFullUrl(url) {
      return `${process.env.VUE_APP_API_BASE_URL}${url}`;
    },

    $apiHandleError(error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorCode = error.response.data.error;

        if (errorCode === 'loginRequired') {
          this.$root.setLoginRequired();
          return true;
        }
  
        this.$root.messageSnackBar.showError(`Error: ${errorCode}`);
        return true;
      }

      return false;
    },
  }
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
